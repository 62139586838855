.App {
  text-align: center;
  font-family: 'Arial', sans-serif;
  background-color: black;
}
body {
  background-color: black;
}
.video-container {
  position: relative;
  margin: 0 auto;
  width: 50vh; 
  max-width: 100%;
}

.video-container video {
  width: 100%;
  height: auto; 
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #0056b3;
}

.answer-input {
  margin-top: 20px;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: white;
  transition: border-color 0.3s;
  text-transform: uppercase;
}

.answer-input.correct {
  border-color: #28a745; 
}

.modal {
  display: none; 
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0, 0.4); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 300px;
  border-radius: 8px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


.guess-answer-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  margin: 20px auto;
  display: block; 
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.guess-answer-btn:hover {
  background-color: #0056b3;
}

.modal {
  display: block; 
}

.modal.hidden {
  display: none;
}

@keyframes wiggle {
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-10px); }
  20%, 40%, 60%, 80% { transform: translateX(10px); }
}

.answer-input.incorrect {
  border-color: red;
  animation: wiggle 0.8s ease-in-out;
}

.check-answer-btn {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #4CAF50; 
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.check-answer-btn:hover {
  background-color: #45a049;
}
.answer-container {
  display:block;
}

.answer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px; 
}

.answer-input, .check-answer-btn {
  margin-top: 10px;
}
.answer-input.correct {
  border: 2px solid green;
}

.answer-input.incorrect {
  border: 2px solid red;
  animation: wiggle 0.5s ease-in-out;
}

@keyframes wiggle {
  0%, 100% { transform: translateX(0px); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
}

.correct-message {
  color: green;
  font-size: 2em;
  font-weight: bold;
  margin: 20px;
  animation: fade-in-out 2s linear;
}

@keyframes fade-in-out {
  0% { opacity: 0; }
  25% { opacity: 1; }
  75% { opacity: 1; }
  100% { opacity: 0; }
}
